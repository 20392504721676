import { Controller } from "@hotwired/stimulus";

function throttle(callback, limit) {
  var waiting = false;
  return function() {
    if (!waiting) {
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(function() {
        waiting = false;
      }, limit);
    }
  };
}

export default class extends Controller {
  static targets = [
    "bookList",
    "book",
    "branch",
    "showBranch",
    "hideBranch",
    "nav",
  ];

  initialize() {
    this.updateNav = throttle(this.updateNav, 150).bind(this);
    this.stickCurrentBook = throttle(this.stickCurrentBook, 5).bind(this);
    this.handleNavHoverStart = this.handleNavHoverStart.bind(this);
    this.handleNavHoverEnd = this.handleNavHoverEnd.bind(this);

    window.addEventListener("scroll", this.updateNav);
    window.addEventListener("scroll", this.stickCurrentBook);
    window.addEventListener("resize", this.stickCurrentBook);

    window.addEventListener("touchstart", () => {
      this.touching = true;
    });

    this.navTargets.forEach((nav) => {
      nav.addEventListener("mouseenter", this.handleNavHoverStart);
      nav.addEventListener("mouseleave", this.handleNavHoverEnd);
    });

    this.updateNav();
    this.stickCurrentBook();
    this.idJump();

    this.bookIds = this.bookTargets.map((book) => book.id);
  }

  handlePrev() {
    const currentBook = this.bookIds.indexOf(this.currentBook()?.id);

    if (currentBook > 0) {
      this.goTo(this.bookIds[currentBook - 1]);
    }
  }

  handleNext() {
    const currentBook = this.bookIds.indexOf(this.currentBook()?.id);

    if (currentBook >= 0 && currentBook < this.bookIds.length) {
      this.goTo(this.bookIds[currentBook + 1]);
    } else {
      this.goTo(this.bookIds[0]);
    }
  }

  handleNav(event) {
    const bookId = event.currentTarget.dataset.parent;
    event.preventDefault();
    this.goTo(bookId);
  }

  handleNavHoverStart(event) {
    if (this.touching) {
      return;
    }

    const item = event.currentTarget;
    const bullet = item.querySelector("a");
    const tooltip = item.querySelector("div");

    tooltip.classList.add("flex");
    tooltip.classList.remove("hidden");

    bullet.classList.add("bg-opacity-70");
    bullet.classList.remove("bg-opacity-0");
  }

  handleNavHoverEnd(event) {
    const item = event.currentTarget;
    const bookId = this.currentBook(200)?.id;

    const bullet = item.querySelector("a");
    const tooltip = item.querySelector("div");

    tooltip.classList.remove("flex");
    tooltip.classList.add("hidden");

    if (item.dataset.parent != bookId) {
      bullet.classList.remove("bg-opacity-70");
      bullet.classList.add("bg-opacity-0");
    }
  }

  handleShowBranch(event) {
    const bookId = event.currentTarget.dataset.parent;

    this.fixStickyBeforeExpand(bookId);
    this.showBranch(bookId);
  }

  handleHideBranch(event) {
    const bookId = event.currentTarget.dataset.parent;

    this.hideBranch(bookId);
    this.fixStickyAfterCollapse(bookId);
  }

  showBranch(bookId) {
    this.branchTargets.forEach((branch) => {
      if (branch.dataset.parent == bookId) {
        branch.classList.remove("hidden");
      }
    });

    this.hideBranchTargets.forEach((button) => {
      if (button.dataset.parent == bookId) {
        button.classList.remove("hidden");
      }
    });

    this.showBranchTargets.forEach((button) => {
      if (button.dataset.parent == bookId) {
        button.classList.add("hidden");
      }
    });
  }

  hideBranch(bookId) {
    this.branchTargets.forEach((branch) => {
      if (branch.dataset.parent == bookId) {
        branch.classList.add("hidden");
      }
    });

    this.showBranchTargets.forEach((button) => {
      if (button.dataset.parent == bookId) {
        button.classList.remove("hidden");
      }
    });

    this.hideBranchTargets.forEach((button) => {
      if (button.dataset.parent == bookId) {
        button.classList.add("hidden");
      }
    });
  }

  updateNav() {
    const bookId = this.currentBook(200)?.id;

    this.navTargets.forEach((nav) => {
      const dot = nav.querySelector("a");

      if (nav.dataset.parent === bookId) {
        dot.classList.remove("bg-opacity-0");
        dot.classList.add("bg-opacity-70");
      } else {
        dot.classList.add("bg-opacity-0");
        dot.classList.remove("bg-opacity-70");
      }
    });
  }

  fixStickyBeforeExpand(bookId) {
    const nextBookId = this.bookIds[this.bookIds.indexOf(bookId) + 1];

    if (!nextBookId || this.protectedBookId === bookId) {
      return;
    }

    const nextBook = this.bookTargets.find(
      (bt) => bt.dataset.title === nextBookId
    );

    const visiblePortion =
      window.innerHeight - nextBook.getBoundingClientRect().top;

    if (visiblePortion > 0) {
      window.scrollBy({ top: -visiblePortion, behavior: "instant" });
    }
  }

  fixStickyAfterCollapse(bookId) {
    const book = this.bookTargets.find((bt) => bt.dataset.title === bookId);

    const { top, bottom } = book.getBoundingClientRect();

    if (top < 0 && bottom < window.innerHeight) {
      this.protectedBookId = bookId;
    }
  }

  stick(book, pos) {
    const content = book.firstElementChild;

    book.style.height = `${content.clientHeight}px`;
    content.classList.remove("bottom-0", "top-0");
    content.classList.add("fixed", "left-0", "right-0", `${pos}-0`);
  }

  unstick(book) {
    const content = book.firstElementChild;

    book.style.height = "auto";
    content.classList.remove("fixed");
  }

  stickCurrentBook() {
    const book = this.currentBook();

    if (!this.canStickBook(book)) {
      this.bookTargets.forEach(this.unstick);
      return;
    }

    const pageTop = window.scrollY;
    const pageBottom = pageTop + window.innerHeight;

    const bookRect = book.getBoundingClientRect();
    const bookHeight = book.firstElementChild.clientHeight;
    const bookTop = window.pageYOffset + bookRect.top;
    const bookBottom = bookTop + bookHeight;

    if (bookTop > pageTop || bookBottom > pageBottom) {
      this.protectedBookId = undefined;
      this.bookTargets.forEach(this.unstick);
      return;
    }

    if (book.dataset.title === this.protectedBookId) {
      return;
    }

    this.protectedBookId = undefined;

    this.bookTargets.forEach((bt) => {
      if (bt.dataset.title !== book.dataset.title) {
        this.unstick(bt);
        return;
      }

      if (bookHeight <= window.innerHeight) {
        this.stick(book, "top");
      } else {
        this.stick(book, "bottom");
      }
    });
  }

  goTo(bookId) {
    const book = this.bookTargets.find(({ id }) => id === bookId);
    book?.scrollIntoView({ behavior: "smooth" });
    setTimeout(this.updateNav, 500);
  }

  currentBook(offset = 0) {
    const minTop = window.scrollY + 1 + offset;

    return this.bookTargets
      .filter((book) => {
        const top = window.pageYOffset + book.getBoundingClientRect().top;
        return top < minTop;
      })
      .pop();
  }

  canStickBook(book) {
    const { top, bottom } = this.bookListTarget.getBoundingClientRect();
    const bookHeight = book?.firstElementChild?.clientHeight;

    return book && top <= 0 && bottom >= bookHeight;
  }

  idJump() {
    const id = window.location.hash.slice(1)
    const book = document.getElementById(id)

    book?.closest(".hidden")?.classList?.remove("hidden")
  }
}
