import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  click() {
    this.element.classList.remove("is-paused");
    this.element.classList.add("is-loading");

    if (this.data.has("media")) {
      const mediaUrl = this.data.get("media");
      const event = document.createEvent("CustomEvent");
      const detail = { mediaUrl };

      event.initCustomEvent("play-click", true, true, detail);
      this.element.dispatchEvent(event);
    }
  }
}
