import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["note"];

  initialize() {
    window.addEventListener("click", this.hideAll.bind(this));

    this.noteTargets.forEach((note) => {
      note.addEventListener("click", (e) => e.stopPropagation());
    });
  }

  show(event) {
    event.preventDefault();
    event.stopPropagation();

    this.hideAll();

    const id = event.currentTarget.dataset.note;
    const note = document.getElementById(`fn-inline-${id}`);
    const arrow = document.getElementById(`fn-arrow-${id}`);

    note.classList.remove("hidden");
    note.style.top = event.target.offsetTop + "px";
    arrow.style.left = event.target.offsetLeft + "px";
  }

  hide(event) {
    event.preventDefault();

    const id = event.currentTarget.dataset.note;
    const note = document.getElementById(`fn-inline-${id}`);

    note.classList.add("hidden");
  }

  hideAll() {
    this.noteTargets.forEach((note) => {
      note.classList.add("hidden");
    });
  }
}
