import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["control"];

  initialize() {
    this.audioElement = document.createElement("audio");
    this.currentControl = null;
    this.playingUrl = null;
    this.playingClasses = ["is-playing", "is-paused", "is-loading"];

    document.addEventListener("turbo:load", () => {
      if (this.isPlaying) {
        this.pause();
      }
    });
  }

  playClicked(event) {
    const url = event.detail.mediaUrl;

    if (event.target == this.currentControl) {
      this.togglePlaying();
    } else {
      this.currentControl = event.target;
      this.startNewTrack(event.target, url);
    }
  }

  startNewTrack(control, url) {
    const audioElement = this.audioElement;

    this.resetControlClasses();

    audioElement.pause();
    audioElement.setAttribute("src", url);
    audioElement.load();

    audioElement.addEventListener(
      "loadeddata",
      function load(event) {
        audioElement.removeEventListener("loadeddata", load, true); // 1 time only
        control.classList.remove("is-loading");
        control.classList.add("is-playing");
        audioElement.play();
      },
      true
    );

    audioElement.addEventListener("ended", () => {
      audioElement.currentTime = 0;
      this.setPlayClass(this.currentControl, "is-paused");
    });
  }

  togglePlaying() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  pause() {
    this.audioElement?.pause();
    this.setPlayClass(this.currentControl, "is-paused");
  }

  play() {
    this.audioElement?.play();
    this.setPlayClass(this.currentControl, "is-playing");
  }

  setPlayClass(element, newClass) {
    element?.classList?.remove(...this.playingClasses);
    element?.classList?.add(newClass);
  }

  resetControlClasses() {
    this.controlsWithout(this.currentControl).forEach((control) =>
      this.setPlayClass(control, "is-paused")
    );
  }

  controlsWithout(element) {
    const targets = [...this.controlTargets]; // create a copy
    const index = targets.indexOf(element);

    targets.splice(index, 1); // take it out of the copied array

    return targets;
  }

  get isPlaying() {
    return !this.audioElement.paused;
  }
}
