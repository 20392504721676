import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["categories", "form", "languages", "songs"];

  initialize() {
    this.initFilter();
    this.filter();

    this.categoriesTarget.querySelectorAll("input").forEach((el) => {
      el.addEventListener("change", this.filter.bind(this));
    });

    this.languagesTarget.querySelectorAll("input").forEach((el) => {
      el.addEventListener("change", this.filter.bind(this));
    });
  }

  initFilter() {
    const params = new URL(document.location).searchParams;

    this.category = params.get("category");
    this.language = params.get("language");
  }

  filter() {
    this.broadcastFilter();
    this.showHideSongs();
    this.updateQueryParams();
  }

  showHideSongs() {
    this.songsTargets.forEach((songs) => {
      if (this.isVisible(songs)) {
        songs.classList.remove("hidden");
      } else {
        songs.classList.add("hidden");
      }
    });
  }

  broadcastFilter() {
    const event = document.createEvent("CustomEvent");

    event.initCustomEvent("songs-filter", true, true);
    this.element.dispatchEvent(event);
  }

  updateQueryParams() {
    const url = new URL(window.location.href);

    url.searchParams.set("category", this.category);
    url.searchParams.set("language", this.language);

    window.history.replaceState({}, "", url.toString());
  }

  isVisible(song) {
    return (
      this.category === song.dataset.category &&
      this.language === song.dataset.language
    );
  }

  get category() {
    return this.categoriesTarget.querySelector("input:checked")?.dataset?.value;
  }

  set category(value) {
    const input = this.categoriesTarget.querySelector(
      `input[data-value=${value}]`
    );

    if (input) {
      input.checked = true;
    }
  }

  get language() {
    return this.languagesTarget.querySelector("input:checked")?.dataset?.value;
  }

  set language(value) {
    const input = this.languagesTarget.querySelector(
      `input[data-value=${value}]`
    );

    if (input) {
      input.checked = true;
    }
  }
}
