import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "header", "content", "opened", "closed"];

  initialize() {
    this.headerTargets.forEach((header) => {
      header.addEventListener("click", (ev) => this.toggleOpen(ev));
    });

    this.itemTargets.forEach((item) => {
      if (this.isOpen(item)) {
        this.open(item);
      } else {
        this.close(item);
      }
    });
  }

  toggleOpen(event) {
    event.preventDefault();
    event.stopPropagation();

    const item = this.item(event.target);

    if (this.isOpen(item)) {
      this.close(item);
    } else {
      this.open(item);
    }
  }

  open(item) {
    this.closeAll();

    item.classList.add("open");
    item.classList.remove("closed");

    this.content(item).classList.remove("hidden");
    this.closed(item).forEach((el) => el.classList.add("hidden"));
    this.opened(item).forEach((el) => el.classList.remove("hidden"));
  }

  close(item) {
    item.classList.remove("open");
    item.classList.add("closed");

    this.content(item).classList.add("hidden");
    this.closed(item).forEach((el) => el.classList.remove("hidden"));
    this.opened(item).forEach((el) => el.classList.add("hidden"));
  }

  closeAll() {
    this.itemTargets.forEach((item) => this.close(item));
  }

  isOpen(item) {
    return !this.content(item).classList.contains("hidden");
  }

  item(header) {
    return header.closest("[data-accordion-target=item]");
  }

  header(item) {
    return item.querySelector("[data-accordion-target=header]");
  }

  content(item) {
    return item.querySelector("[data-accordion-target=content]");
  }

  opened(item) {
    return item.querySelectorAll("[data-accordion-target=opened]");
  }

  closed(item) {
    return item.querySelectorAll("[data-accordion-target=closed]");
  }
}
