import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    action: String,
    frame: String,
  }

  connect() {
    this.element.addEventListener("change", () => {
      if (this.actionValue === "replace") {
        history.replaceState({}, null, this.element.value)
      } else if (this.actionValue === "advance") {
        history.pushState({}, null, this.element.value)
      }

      Turbo.visit(this.element.value, { action: this.actionValue, frame: this.frameValue });
    });
  }
}
