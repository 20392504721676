import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "selected", "option", "label", "mark"];

  initialize() {
    this.inputTarget.addEventListener("change", this.selectOption.bind(this));
    this.optionTargets.forEach((option) =>
      option.addEventListener("click", () => this.handleOptionChange(option))
    );

    this.selectOption();
  }

  handleOptionChange(option) {
    this.inputTarget.value = option.dataset.value;
    this.inputTarget.dispatchEvent(new CustomEvent("change"));
  }

  selectOption() {
    const option = this.selectedOption();

    const label = option.querySelector('[data-select-target="label"]');
    const mark = option.querySelector('[data-select-target="mark"]');

    this.selectedTarget.innerHTML = label.innerHTML;

    this.markTargets.forEach((mark) => {
      mark.classList.add("hidden");
      mark.classList.remove("flex");
    });

    mark?.classList?.remove("hidden");
    mark?.classList?.add("flex");
  }

  selectedOption() {
    return this.optionTargets.find(
      (option) => option.dataset.value === this.inputTarget.value
    );
  }
}
