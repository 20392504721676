import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "prev", "next"];

  initialize() {
    this.index = 0;
    this.size = this.imageTargets.length;

    this.show();
  }

  handlePrev() {
    this.index = (this.index - 1 + this.size) % this.size;
    this.show();
  }

  handleNext() {
    this.index = (this.index + 1) % this.size;
    this.show();
  }

  show() {
    this.imageTargets.forEach((image) => {
      if (image.dataset.index == this.index) {
        image.classList.add("opacity-1");
        image.classList.remove("opacity-0");
      } else {
        image.classList.add("opacity-0");
        image.classList.remove("opacity-1");
      }
    });

    if (this.index == 0) {
      this.prevTarget.classList.add("hidden");
    } else {
      this.prevTarget.classList.remove("hidden");
    }

    if (this.index == this.size - 1) {
      this.nextTarget.classList.add("hidden");
    } else {
      this.nextTarget.classList.remove("hidden");
    }
  }
}
